export default {
  api: {
    url: 'https://stagapi.fitplus.biz/fitstar/api/', // stag api //changed
    mode: 'cors',
    socket:'https://stagsocket.fitplus.biz'
  },
  firebaseConfig: {
    apiKey: "AIzaSyAhMbkezcOXFUdGpfT4Bp4W_d7ANXBCKVs",
    authDomain: "fitplus-dev.firebaseapp.com",
    databaseURL: "https://fitplus-dev.firebaseio.com",
    projectId: "fitplus-dev",
    storageBucket: "fitplus-dev.appspot.com",
    messagingSenderId: "164811617082",
    appId: "1:164811617082:web:a7afabcbd8dc09026c1603",
    measurementId: "G-9W4TX7F8NC"
  },
  sfsserver:{

    host:"sfs-stag.onefitplus.com",
    port:8443,
    ssl:true
  }
}
